import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from './store'
import ElementPlus from '@/store/element'
import 'element-plus/theme-chalk/index.css'
import '@/assets/style/msg.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.mount('#app')
