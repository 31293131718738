import {
  ElConfigProvider,
  ElAlert,
  ElAside,
  ElAutocomplete,
  // ElAvatar,
  // ElBacktop,
  // ElBadge,
  // ElBreadcrumb,
  // ElBreadcrumbItem,
  // ElButtonGroup,
  // ElCalendar,
  ElCard,
  // ElCarousel,
  // ElCarouselItem,
  // ElCascader,
  // ElCascaderPanel,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup,
  // ElCollapse,
  // ElCollapseItem,
  // ElCollapseTransition,
  // ElColorPicker,
  ElContainer,
  // ElDatePicker,
  ElDialog,
  ElDivider,
  ElDrawer,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  // ElFooter,
  ElForm,
  ElFormItem,
  ElHeader,
  ElIcon,
  ElInputNumber,
  ElImage,
  ElMain,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElOption,
  ElOptionGroup,
  // ElPageHeader,
  ElPagination,
  // ElPopconfirm,
  ElPopover,
  // ElPopper,
  // ElProgress,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  // ElRate,
  ElCol,
  ElRow,
  // ElScrollbar,
  ElLink,
  // ElSlider,
  // ElStep,
  // ElSteps,
  ElSubMenu,
  ElSwitch,
  ElTabPane,
  ElTabs,
  ElTable,
  ElTableColumn,
  ElTag,
  // ElTimePicker,
  // ElTimeSelect,
  // ElTimeline,
  // ElTimelineItem,
  ElTooltip,
  ElTransfer,
  ElTree,
  ElUpload,
  // ElInfiniteScroll,
  // ElLoading,
  // ElMessage,
  // ElMessageBox,
  // ElNotification,
  ElColorPicker,
  ElDatePicker,
  ElDescriptions,
  ElDescriptionsItem,
  ElEmpty
} from 'element-plus'
const components = [
  ElConfigProvider,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElLink,
  ElCard,
  ElForm,
  ElFormItem,
  ElHeader,
  ElIcon,
  ElInputNumber,
  ElAside,
  ElContainer,
  ElMain,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElSubMenu,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElTabPane,
  ElTabs,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElTag,
  ElDialog,
  ElTree,
  ElOption,
  ElOptionGroup,
  ElCol,
  ElRow,
  ElColorPicker,
  ElDatePicker,
  ElSwitch,
  ElPopover,
  ElImage,
  ElDescriptions,
  ElDescriptionsItem,
  ElDivider,
  ElDrawer,
  ElTooltip,
  ElTransfer,
  ElUpload,
  ElAlert,
  ElEmpty,
  ElAutocomplete
]; const options = { size: 'small', zIndex: 3000 }
//  eslint-disable-next-line
const install = function ins(app: any): void {
  // if (install.installed) { return }
  for (let i = 0; i < components.length; i++) {
    app.component(components[i].name, components[i])
  }
  app.config.globalProperties.$ELEMENT = options
}
const API = {
  install
}
export default Object.assign(API, components)
