<template>
    <div class="home">
      <ActivityComponents msg="撸豆人专属活动页面"/>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue'
  import ActivityComponents from '@/components/ActivityComponents.vue' // @ is an alias to /src
  
  export default defineComponent({
    name: 'activityPage',
    components: {
      ActivityComponents
    }
  })
  </script>