<template>
    <div class="home">
      <XiaomiSign msg="小米-当日签到奖励"/>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue'
  import XiaomiSign from '@/components/XiaomiSign.vue' // @ is an alias to /src
  
  export default defineComponent({
    name: 'signList',
    components: {
        XiaomiSign
    }
  })
  </script>
  