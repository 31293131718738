interface ApiResult<T> {
    page: any
    returnCode: string
    returnData: T
    returnMsg: string
  }

export async function get<T = any> (url: string) {
  const res = await new Promise<ApiResult<T>>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    console.log(url)
    url = url + (url.includes('?') ? '&' : '?') + '_=' + Date.now()
    xhr.open('GET', url)
    xhr.responseType = 'json'
    // IE9+
    if ('onload' in XMLHttpRequest.prototype) {
      xhr.onload = function () {
        resolve(getResponseJson(xhr))
      }
      xhr.onerror = function () {
        reject(new Error(`Error ${xhr.status}: ${xhr.statusText}`))
      }
    } else {
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          resolve(getResponseJson(xhr))
        }
      }
    }
    xhr.send()
  })
  return handleResponse(res)
}

export async function postData<T = unknown> (
  url: string,
  data?: Record<string, unknown> | null
) {
  const res = await new Promise<ApiResult<T>>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url)
    const token = localStorage.getItem('token')
    if (token) {
      xhr.setRequestHeader('token', token)
    }
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
    // IE 不支持 json 类型
    xhr.responseType = 'json'
    // IE9+
    if ('onload' in XMLHttpRequest.prototype) {
      xhr.onload = function () {
        resolve(getResponseJson(xhr))
      }
      xhr.onerror = function () {
        reject(new Error(`Error ${xhr.status}: ${xhr.statusText}`))
      }
    } else {
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          resolve(getResponseJson(xhr))
        }
      }
    }
    xhr.send(data && JSON.stringify(data))
  })
  return handleResponse(res)
}

async function getResponseJson (xhr: XMLHttpRequest) {
  if (xhr.status !== 200) {
    throw new Error(`Error ${xhr.status}: ${xhr.statusText}`)
  }
  // IE8 不支持
  // IE9-11 不支持返回 json 类型，其是字符串
  const res = xhr.response
  if (typeof res === 'object') {
    return res
  }
  const text = xhr.responseText
  if (text) {
    const newText = JSON.parse(text)
    return newText
  }
  throw new Error('未返回数据')
}

function handleResponse<T> (res: ApiResult<T>) {
  const newRes: ApiResult<T> = res !== undefined ? typeof res === 'object' ? res : JSON.parse(res) : undefined
  if (newRes === undefined || newRes.returnCode === undefined) {
    throw new Error('数据异常')
  }

  if (newRes.returnCode !== '200') {
    throw new Error(newRes.returnMsg)
  }
  return newRes.returnData ? newRes.returnData : newRes.page
}
