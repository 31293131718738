import { createRouter, createWebHistory } from 'vue-router'
import signList from '@/views/XiaomiSign.vue'
import activityPage from '@/views/ActivityPage.vue'
 
const routes = [
  { path: '/', redirect: '/activity' }, // 默认重定向到 /a
  { path: '/sign', component: signList },
  { path: '/activity', component: activityPage }
]
 
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
 
export default router